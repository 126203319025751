<template>
  <DashboardInformationSettingsStyled>
    <DropdownStyled>
      <BasicSelect
        class="basic-select"
        :options="classOptions"
        :selected="selectedClassOption"
        :target="$t('dashboardInformationSettings.select')"
        :isDisabled="!hasTalpaRoles"
        @change="setClassOption"
      />
    </DropdownStyled>
  </DashboardInformationSettingsStyled>
</template>

<script>
import { BasicSelect } from '@common/components'

import { styled } from '@egoist/vue-emotion'
import localesMixin from '@/mixins/locales'
import { getRolesFromToken } from '@common/utils'

const DashboardInformationSettingsStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  input {
    height: -webkit-fill-available;
    width: 98%;
    border-radius: 10px;
    border: none;
    padding: 0.5rem;
    border: ${p => p.theme.colors.selectBG} 1px solid;
  }
`
const DropdownStyled = styled('div')`
  display: flex;
  flex-direction: row;
  z-index: 2;
  .basic-select {
    width: 15rem;
    border-radius: 10px;
    > .current {
      padding-left: 0;
      > .target {
        width: max-content;
        font-size: 14px;
      }
    }
    > .open {
      height: 10rem;
      overflow: auto;
    }
  }
`

export default {
  mixins: [localesMixin],
  components: {
    BasicSelect,
    DashboardInformationSettingsStyled,
    DropdownStyled,
  },
  props: {
    widgetSettings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedClassOption: { id: 1 },
    }
  },
  validations: {},
  computed: {
    classOptions() {
      return [
        {
          id: 1,
          label: this.$t('dashboardInformationSettings.default'),
          name: 'NO_LOGO',
        },
        {
          id: 2,
          label: 'DEUTZ',
          name: 'DEUTZ',
        },
        {
          id: 3,
          label: 'EPIROC',
          name: 'EPIROC',
        },
        {
          id: 4,
          label: 'GHH',
          name: 'GHH',
        },
        {
          id: 5,
          label: 'SANDVIK',
          name: 'SANDVIK',
        },
        {
          id: 6,
          label: 'SMAGlink',
          name: 'SMAG',
        },
        {
          id: 7,
          label: 'TALPA',
          name: 'TALPA',
        },
        {
          id: 8,
          label: 'BRIDGESTONE',
          name: 'BRIDGESTONE',
        },
        {
          id: 9,
          label: 'EYE',
          name: 'EYE',
        },
        {
          id: 10,
          label: 'WEYCOR',
          name: 'WEYCOR',
        },
      ]
    },
    userRoles() {
      return getRolesFromToken(this.$keycloak.token)
    },
    hasTalpaRoles() {
      return this.userRoles.includes('archon') || this.userRoles.includes('talpa-product') || this.userRoles.includes('talpa-basic')
    },
  },
  watch: {
    widgetSettings: {
      handler(widgetSettings) {
        this.initDefaultValues(widgetSettings)
      },
      immediate: true,
    },
  },
  methods: {
    setClassOption(value) {
      this.selectedClassOption = value
      const dashboardInformationSettings = {
        name: value.name,
      }
      this.$emit('changeWidgetSettings', { ...dashboardInformationSettings })
    },
    initDefaultValues(widgetSettings) {
      if (!widgetSettings.name) {
        this.selectedClassOption = this.classOptions[0]
      } else {
        this.selectedClassOption = this.classOptions.find(option => option.name === widgetSettings.name)
      }
    },
  },
}
</script>
